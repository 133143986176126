import { campaignAnalyticsTypes } from '../../constants/types';
import Vue from 'vue';

const { setLiveCustomerCount, setLiveItemCount, loadDefaultCampaignAnalytics } = campaignAnalyticsTypes.actions;

export const mutations = {
  [setLiveCustomerCount]: (state, { response }) => {
    Vue.set(state, 'liveCustomerCount', response.data.value);
  },
  [setLiveItemCount]: (state, { response }) => {
    Vue.set(state, 'liveItemCount', response.data.value);
  },
  [loadDefaultCampaignAnalytics]: (state, { response }) => {
    Vue.set(state, 'defaultCampaignAnalytics', response.data.value);
  }
};
