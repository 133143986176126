import VueRouter from 'vue-router';
import routes from './routes';
import { Auth } from 'aws-amplify';
import store from '../store';
import { globalTypes } from '../constants/types';

// configure router
export const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

/**
 * Routes that certain roles don't have access to
 */
export const prevents = {
  viewer: [],
  admin: []
};

const hasAccess = (to, role) => {
  if (prevents[role].indexOf(to.name) > -1) {
    return false;
  } else {
    return true;
  }
};

router.beforeEach(async (to, from, next) => {
  if (to.path === '/tenant-selection' || to.path === '/onboarding') {
    return next();
  }

  to.meta.previousRoute = from;
  if (to.matched.some(record => record.meta.requiresAuth)) {
    try {
      const authenticated = await Auth.currentAuthenticatedUser();

      if (!authenticated) next('/login');
      else {
        const tenantId = authenticated.attributes['custom:tenant_ids'];
        const role = authenticated.attributes['custom:role'];
        const onboardingStatus = authenticated.attributes['custom:onboarding_status'];

        const passed = hasAccess(to, role);
        localStorage.setItem('role', role);

        if ((!tenantId && to.path !== '/login') || !passed) {
          next('/login');
        } else {
          if (!onboardingStatus || onboardingStatus < 6) return next('/onboarding');

          if (!sessionStorage.getItem('tenantId') && to.path !== '/tenant-selection') {
            return next('/tenant-selection');
          }
          await store.dispatch(globalTypes.dispatchers.setTenantFeatures).then(response => {
            store.commit(globalTypes.dispatchers.setTenantFeatures, { response });
          });

          await store.dispatch(globalTypes.dispatchers.setTenantUserPermissions).then(response => {
            store.commit(globalTypes.dispatchers.setTenantUserPermissions, { response });
          });

          const can =
            to.meta.featureCodename && to.meta.permissionCodename
              ? store.getters.getTenantFeatures('SUBSCRIBED', to.meta.featureCodename) &&
                store.getters.getTenantUserPermissions('AUTHORIZED', to.meta.permissionCodename)
              : true;

          if (can) {
            next();
          } else {
            next('/not-found');
          }
        }
        // await store.dispatch(globalTypes.dispatchers.setTenantOnboardingStatus);
        // const onboarding_status = store.getters.getTenantOnboardingStatus;
        // if (onboarding_status === 'done' && to.path === '/onboarding') {
        //   next('/dashboard');
        // }

        // if (onboarding_status != 'done') {
        //   next('/onboarding?step=' + onboarding_status);
        // }
      }
    } catch (err) {
      console.log('error', err);
      if (to.path !== '/login') {
        try {
          const authenticated = await Auth.currentAuthenticatedUser();
          if (authenticated) next('/loading-screen');
        } catch {
          next('/login');
        }
      } else {
        next();
      }
    }
  } else {
    if (to.path === '/callback') {
      try {
        const authenticated = await Auth.currentAuthenticatedUser();
        if (authenticated) next('/');
      } catch {
        next();
      }
    } else next();
  }
});

export default router;
