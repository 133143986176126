export const getDefaultState = () => {
  return {
    liveCustomerCount: '',
    liveItemCount: '',
    defaultCampaignAnalytics: {
      sent: 0,
      bounce: 0,
      delivery: 0,
      open: 0,
      click: 0,
      sale: 0,
      unsubscribe: 0,
      bounce_rate: 0,
      delivery_rate: 0,
      open_rate: 0,
      click_through_rate: 0,
      click_to_open_rate: 0,
      conversion_rate: 0
    }
  };
};
