import DashboardLayout from '../pages/Layout/DashboardLayout.vue';
import LoadingScreen from '../pages/LoadingScreen/LoadingScreen.vue';

// GeneralViews
import NotFound from '../pages/GeneralViews/NotFoundPage.vue';

const Login = () => import(/* webpackChunkName: "dashboard" */ '../pages/Login/index.vue');
const TenantSelection = () => import(/* webpackChunkName: "dashboard" */ '../pages/TenantSelection/index.vue');
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '../pages/Dashboard/index.vue');
const Profile = () => import(/* webpackChunkName: "common" */ '../pages/Profile.vue');

const Customers = () => import(/* webpackChunkName: "common" */ '../pages/Customers/Customers.vue');
const CustomerAnalytics = () => import(/* webpackChunkName: "common" */ '../pages/Customers/CustomersAnalytics.vue');
const Products = () => import(/* webpackChunkName: "common" */ '../pages/Products/Products.vue');
const ProductAnalytics = () => import(/* webpackChunkName: "common" */ '../pages/Products/ProductAnalytics.vue');

// Campaigns
const MarketingCampaigns = () => import(/* webpackChunkName: "common" */ '../pages/Campaign/Campaigns.vue');
const NewCampaign = () => import(/* webpackChunkName: "common" */ '../pages/Campaign/Create.vue');
const EditCampaign = () => import(/* webpackChunkName: "common" */ '../pages/Campaign/Edit.vue');

const DataUpload = () => import(/* webpackChunkName: "common" */ '../pages/DataManager/DataUpload.vue');
const DataManager = () => import(/* webpackChunkName: "common" */ '../pages/DataManager/DatasetGroups.vue');

const Recommendations = () => import(/* webpackChunkName: "common" */ '../pages/Recommendations/index.vue');
const RecommendationsAnalytics = () =>
  import(/* webpackChunkName: "common" */ '../pages/RecommendationsAnalytics/index.vue');
const Segments = () => import(/* webpackChunkName: "common" */ '../pages/Segment/Segments.vue');
const NewSegment = () => import(/* webpackChunkName: "common" */ '../pages/Segment/Create/index.vue');
const UpdateSegment = () => import(/* webpackChunkName: "common" */ '../pages/Segment/Update/index.vue');
const Filters = () => import(/* webpackChunkName: "common" */ '../pages/Filters/Filters.vue');
const NewFilter = () => import(/* webpackChunkName: "common" */ '../pages/Filters/Create/index.vue');
const EditFilter = () => import(/*  webpackChunkName: "common" */ '../pages/Filters/Update/index.vue');

const Settings = () => import(/* webpackChunkName: "common" */ '../pages/Settings.vue');

// Segment Analytics
const SegmentAnalytics = () => import(/* webpackChunkName: "common" */ '../pages/SegmentAnalytics/index.vue');
// Campaign Analytics
const CampaignAnalytics = () => import(/* webpackChunkName: "common" */ '../pages/CampaignAnalytics/index.vue');

// Shopify
const ShopifyConnector = () => import(/* webpackChunkName: "common" */ '../pages/Connectors/Shopify/index.vue');

// Error pages
const Fallback = () => import(/* webpackChunkName: "common" */ '../pages/Error/Fallback.vue');

// Template builder
const CreateTemplate = () => import(/* webpackChunkName: "common" */ '../pages/Templates/CreateTemplate.vue');

// Template builder
const Templates = () => import(/* webpackChunkName: "common" */ '../pages/Templates/Templates.vue');

// Marketing Automation
const MarketingAutomation = () => import(/* webpackChunkName: "common" */ '../pages/MarketingAutomation/index.vue');

// Customers Email Template
const CustomerEmail = () => import(/* webpackChunkName: "common" */ '../pages/CustomerEmail/index.vue');

const routes = [
  {
    path: '/onboarding',
    name: 'onboarding',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/chatbot-settings',
    name: 'Chatbot-Settings',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/connector-settings',
    name: 'Connector-Settings',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/customer-email/:user_id/:id',
    name: 'Customer-email',
    component: CustomerEmail,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/loading-screen',
    name: 'loading-screen',
    component: LoadingScreen,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFound,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/tenant-selection',
    name: 'tenant-selection',
    component: TenantSelection,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Loading Screen',
    meta: {
      requiresAuth: true,
      featureCodename: 'DASHBOARD',
      permissionCodename: 'DASHBOARD.VIEW'
    },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          requiresAuth: true,
          featureCodename: 'DASHBOARD',
          permissionCodename: 'DASHBOARD.VIEW'
        }
      },
      {
        path: 'fallback',
        name: 'fallback',
        component: Fallback,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'settings',
        name: 'settings',
        component: Settings,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'customers-analytics/:id',
        name: 'customers-analytics',
        component: CustomerAnalytics,
        meta: {
          requiresAuth: true,
          featureCodename: 'CUSTOMERS_ANALYTICS',
          permissionCodename: 'CUSTOMERS_ANALYTICS.VIEW'
        }
      },
      {
        path: 'products-analytics/:id',
        name: 'products-analytics',
        component: ProductAnalytics,
        meta: {
          requiresAuth: true,
          featureCodename: 'PRODUCTS_ANALYTICS',
          permissionCodename: 'PRODUCTS_ANALYTICS.VIEW'
        }
      },
      {
        path: 'create-template',
        name: 'create-template',
        component: CreateTemplate,
        meta: {
          requiresAuth: true,
          featureCodename: 'TEMPLATES',
          permissionCodename: 'TEMPLATES.CREATE'
        }
      },
      {
        path: 'templates',
        name: 'templates',
        component: Templates,
        meta: {
          requiresAuth: true,
          featureCodename: 'TEMPLATES',
          permissionCodename: 'TEMPLATES.VIEW'
        }
      },
      {
        path: 'templates/:id',
        name: 'modify-templates',
        component: CreateTemplate,
        meta: {
          requiresAuth: true,
          featureCodename: 'TEMPLATES',
          permissionCodename: 'TEMPLATES.EDIT'
        }
      },
      {
        path: 'products',
        name: 'products',
        component: Products,
        meta: {
          requiresAuth: true,
          featureCodename: 'PRODUCTS',
          permissionCodename: 'PRODUCTS.VIEW'
        },
        props: {
          container: 'products'
        }
      },
      {
        path: 'marketing-campaigns',
        name: 'marketing-campaigns',
        component: MarketingCampaigns,
        meta: {
          requiresAuth: true,
          featureCodename: 'CAMPAIGNS',
          permissionCodename: 'CAMPAIGNS.VIEW'
        }
      },
      {
        path: 'new-campaign',
        name: 'new-campaign',
        component: NewCampaign,
        meta: {
          requiresAuth: true,
          featureCodename: 'CAMPAIGNS',
          permissionCodename: 'CAMPAIGNS.CREATE'
        }
      },
      {
        path: 'campaigns/:id',
        name: 'campaign',
        component: EditCampaign,
        meta: {
          requiresAuth: true,
          featureCodename: 'CAMPAIGNS',
          permissionCodename: 'CAMPAIGNS.EDIT'
        }
      },
      {
        path: 'data-upload',
        name: 'data-upload',
        component: DataUpload,
        meta: {
          requiresAuth: true,
          featureCodename: 'DATA_UPLOAD',
          permissionCodename: 'DATA_UPLOAD.VIEW'
        }
      },
      {
        path: 'data-groups',
        name: 'data-groups',
        component: DataManager,
        meta: {
          requiresAuth: true,
          featureCodename: 'DATASETS_GROUP',
          permissionCodename: 'DATASETS_GROUP.VIEW'
        }
      },
      {
        path: 'customers',
        name: 'customers',
        component: Customers,
        meta: {
          requiresAuth: true,
          featureCodename: 'CUSTOMERS',
          permissionCodename: 'CUSTOMERS.VIEW'
        }
      },
      {
        path: 'recommendations',
        name: 'recommendations',
        component: Recommendations,
        meta: {
          requiresAuth: true,
          featureCodename: 'RECOMMENDATIONS.EMAIL',
          permissionCodename: 'RECOMMENDATIONS.EMAIL.VIEW'
        }
      },
      // {
      //   path: 'template-manager',
      //   component: TemplateManager,
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      {
        path: 'recommendations-analytics',
        component: RecommendationsAnalytics,
        meta: {
          requiresAuth: true,
          featureCodename: 'RECOMMENDATIONS_ANALYTICS',
          permissionCodename: 'RECOMMENDATIONS_ANALYTICS.VIEW'
        }
      },
      {
        path: '/connectors/shopify',
        component: ShopifyConnector,
        meta: {
          requiresAuth: true,
          featureCodename: 'CONNECTORS.SHOPIFY',
          permissionCodename: 'CONNECTORS.SHOPIFY.INSTALL'
        }
      },
      {
        path: 'filters',
        name: 'filters',
        component: Filters,
        meta: {
          requiresAuth: true,
          featureCodename: 'PRODUCTS_FILTERS',
          permissionCodename: 'PRODUCTS_FILTERS.VIEW'
        }
      },
      {
        path: 'filters/:id',
        name: 'edit-filter',
        component: EditFilter,
        meta: {
          requiresAuth: true,
          featureCodename: 'PRODUCTS_FILTERS',
          permissionCodename: 'PRODUCTS_FILTERS.EDIT'
        }
      },
      {
        path: 'filter-create',
        name: 'filter-create',
        component: NewFilter,
        meta: {
          requiresAuth: true,
          featureCodename: 'PRODUCTS_FILTERS',
          permissionCodename: 'PRODUCTS_FILTERS.CREATE'
        }
      },
      {
        path: 'segments',
        name: 'segments',
        component: Segments,
        meta: {
          requiresAuth: true,
          featureCodename: 'SEGMENTS',
          permissionCodename: 'SEGMENTS.VIEW'
        }
      },
      {
        path: 'segment-create',
        name: 'segment-create',
        component: NewSegment,
        meta: {
          requiresAuth: true,
          featureCodename: 'SEGMENTS',
          permissionCodename: 'SEGMENTS.CREATE'
        }
      },
      {
        path: 'segments/:id',
        name: 'edit-segment',
        component: UpdateSegment,
        meta: {
          requiresAuth: true,
          featureCodename: 'SEGMENTS',
          permissionCodename: 'SEGMENTS.EDIT'
        }
      },
      {
        path: 'segment-analytics/:id',
        name: 'segment-analytics',
        component: SegmentAnalytics,
        meta: {
          requiresAuth: true,
          featureCodename: 'SEGMENTS_ANALYTICS',
          permissionCodename: 'SEGMENTS_ANALYTICS.VIEW'
        }
      },
      {
        path: 'campaign-analytics/:id',
        name: 'campaign-analytics',
        component: CampaignAnalytics,
        meta: {
          requiresAuth: true,
          featureCodename: 'CAMPAIGN_ANALYTICS',
          permissionCodename: 'CAMPAIGN_ANALYTICS.VIEW'
        }
      },
      {
        path: 'marketing-automation',
        name: 'marketing-automation',
        component: MarketingAutomation,
        meta: {
          requiresAuth: true,
          featureCodename: 'CAMPAIGNS_AUTOMATION',
          permissionCodename: 'CAMPAIGNS_AUTOMATION.VIEW'
        }
      }
    ]
  },
  {
    path: '*',
    component: NotFound
  }
];

export default routes;
