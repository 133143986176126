import { actions } from './actions';
import { mutations } from './mutations';
import { getDefaultState } from './state';

export const campaignAnalytics = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getLiveCustomerCount: state => state.liveCustomerCount,
    getLiveItemCount: state => state.liveItemCount,
    getDefaultCampaignAnalytics: state => state.defaultCampaignAnalytics
  },
  actions,
  mutations
};
